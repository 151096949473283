import React from 'react';
import Layout from "../../layout";

const Home = () => {
    return (
        <Layout title="Home">

        </Layout>
    );
};

export default Home;